const messages = {
  id: 'Id',
  title: 'Názov',
  slug: 'Slug/Názov pre URL',
  description: 'Popis',
  image: 'Obrázok',
  type: 'Typ tagu',
  tag_name_already_exists: "Tag s názvom '{name}' už existuje.",
  list: {
    id: 'Id',
    title: 'Názov tagu',
    created_at: 'Dátum vytvorenia',
    modified_at: 'Dátum poslednej zmeny',
    created_by: 'Vytvoril'
  },
  filter: {
    title: 'Titulok',
    id: 'Id',
    search: 'Hľadať'
  },
  commonTags: {
    geneeaTags: 'Geneea tagy',
    editorialTags: 'Redakčné tagy'
  },
  geneeaTag: {
    title: 'Názov tagu',
    id: 'Id',
    geneeaId: 'Geneea Id',
    type: 'Typ tagu',
    url: 'URL',
    generalInfo: {
      title: 'Všeobecné informácie o tagu',
      slug: 'Slug/Názov pre URL',
      createdAt: 'Dátum vytvorenia',
      modifiedAt: 'Dátum poslednej zmeny'
    },
    person: {
      title: 'Osoba',
      familyName: 'Rodinné meno',
      birthDate: 'Dátum narodenia',
      deathDate: 'Dátum úmrtia',
      hasOccupation: 'Povolanie',
      givenName: 'Krstné meno',
      birthPlace: 'Miesto narodenia',
      deathPlace: 'Miesto úmrtia'
    },
    organisation: {
      title: 'Organizácia',
      name: 'Meno',
      alternateName: 'Alternatívne meno',
      logo: 'Logo'
    },
    event: {
      title: 'Udalosť',
      name: 'Názov udalosti',
      startDate: 'Dátum začiatku',
      endDate: 'Dátum konca'
    },
    product: {
      title: 'Produkt',
      name: 'Produkt',
      manufacturer: 'Výrobca',
      model: 'Model'
    },
    location: {
      title: 'Poloha',
      name: 'Názov lokácie',
      gps: 'GPS',
      streetAddress: 'Adresa',
      addressLocation: 'Okres',
      addressRegion: 'Kraj',
      addressCountry: 'Krajina',
      population: 'Populácia'
    },
    general: {
      title: 'Všeobecný'
    }
  }
}

export default messages
