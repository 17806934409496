const messages = {
  copy: {
    first: 'HP blok',
    last: 'byl zkopírován'
  },
  list: {
    id: 'Id',
    type: 'Typ',
    title: 'Název',
    identifier: 'Identifikátor',
    number_of_items: 'Počet položek',
    external_fallback: 'Externí fallback',
    internal_fallback: 'Interní fallback',
    fallback_article_types: 'Fallback typy článků',
    fallback_article_flags: 'Fallback flagy článků',
    hidden: 'Skrytý',
    position: 'Pozice',
    created_at: 'Vytvořený',
    modified_at: 'Upravený',
    created_by: 'Vytvořil'
  },
  filter: {
    site: 'Stránka',
    type: 'Typ',
    title: 'Název',
    identifier: 'Identifikátor',
    id: 'Id',
    search: 'Hledat'
  },
  id: 'Id',
  hidden: 'Skrytý',
  type: 'Typ',
  title: 'Název',
  identifier: 'Identifikátor',
  site: 'Stránka',
  category: 'Kategorie',
  number_of_items: 'Počet položek',
  position: 'Pozice',
  daysLimit: 'Query limit days',
  display_position: 'Display position',
  disabled_positions: 'Disabled positions',
  fallback_positions: 'Fallback positions',
  external_fallback: 'Enable external fallback',
  internal_fallback: 'Enable internal fallback',
  fallback_site: 'Fallback stránka',
  fallback_rubrics: 'Fallback rubriky',
  fallback_tags: 'Fallback tagy',
  fallback_article_types: 'Article types (logical OR will be apploed)',
  fallback_article_flags: 'Article\'s Flags (logical OR will be applied)',
  fallback_article_settings: 'Article\'s Settings',
  fallback_categories: 'Fallback categories',
  content_block_type_value: {
    article: 'Article',
    video_article: 'Video article',
    video: 'Video'
  },
  main_settings: 'Hlavní nastavení',
  fallback_settings: 'Fallback nastavení',
  gallery: 'Galerie',
  pr: 'Jen PR články'
}

export default messages
