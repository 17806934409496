import { getOrInitializeDataLayer, hasScript, loadScript } from './utils'

import { isEqual } from 'lodash'
import { GTM_BRAND_INFO, GTM_PARENT_BRAND_INFO } from '@/plugins/gtm/config'

/**
 * The GTM Support main class.
 */
export class GtmSupport {
  constructor (options) {
    this.id = options.id
    this.options = {
      enabled: true,
      loadScript: true,
      defer: false,
      compatibility: false,
      vueRouter: null,
      ...options
    }

    delete this.options.id
    this.scriptElements = []
  }

  isInBrowserContext () {
    return typeof window !== 'undefined'
  }

  enabled () {
    return this.options.enabled ?? true
  }

  enable (enabled = true, source) {
    this.options.enabled = enabled

    if (this.isInBrowserContext() && enabled && !hasScript(source) && this.options.loadScript) {
      const scriptElement = loadScript(this.id, { ...this.options })
      this.scriptElements.push(scriptElement)
    }
  }

  dataLayer () {
    if (this.isInBrowserContext() && this.options.enabled) {
      return getOrInitializeDataLayer(window)
    }
    return false
  }

  trackView (event, pageType, articleBrand, ...rest) {
    const trigger = this.isInBrowserContext() && (this.options.enabled ?? false)

    if (trigger) {
      const dataLayer = getOrInitializeDataLayer(window)
      dataLayer.push({
        event: event ?? 'pageView',
        page_info: {
          page_type: pageType || this.options.vueRouter.currentRoute.name,
          article_brand: articleBrand || 'all'
        },
        brand_info: {
          brand: GTM_BRAND_INFO,
          parent_brand: GTM_PARENT_BRAND_INFO
        },
        ...rest
      })
    }
  }

  trackUser ({ email, firstName, lastName }) {
    const trigger = this.isInBrowserContext() && (this.options.enabled ?? false)
    const newUser = {
      user_info: {
        user_email: email,
        user_name: `${firstName}.${lastName}`.toLowerCase()
      }
    }
    const dataLayer = getOrInitializeDataLayer(window)
    const userExists = dataLayer.some(user => isEqual(user, newUser))
    if (!trigger || userExists) {
      return
    }

    dataLayer.push(newUser)
  }

  trackEvent ({
    event,
    pageType = null,
    category = null,
    action = null,
    value = null,
    noninteraction = false,
    ...rest
  } = {}) {
    const trigger = this.isInBrowserContext() && (this.options.enabled ?? false)

    if (trigger) {
      const dataLayer = getOrInitializeDataLayer(window)
      dataLayer.push({
        event: event ?? 'interaction',
        target: category,
        action: action,
        value: value,
        page_info: {
          page_type: pageType
        },
        ...rest
      })
    }
  }

  push (data) {
    const trigger = this.isInBrowserContext() && (this.options.enabled ?? false)

    if (trigger) {
      const dataLayer = getOrInitializeDataLayer(window)
      dataLayer.push(data)
    }
  }
}
