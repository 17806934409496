const messages = {
  list: {
    id: 'Id',
    title: 'Otázka',
    type: 'Typ',
    hidden: 'Skrytý',
    enabled_voting: 'Povoliť hlasovanie',
    category: 'Category',
    created_at: 'Dátum vytvorenia',
    modified_at: 'Dátum poslednej zmeny',
    created_by: 'Vytvoril'
  },
  filter: {
    title: 'Otázka',
    id: 'Id',
    type: 'Type',
    category: 'Category',
    search: 'Hľadať'
  },
  id: 'Id',
  title: 'Otázka',
  title_placeholder: 'Napište názov ankety',
  city: 'Mesto',
  description: 'Popis',
  type: 'Typ',
  hidden: 'Skrytý',
  image: 'Obrázok',
  enabled_voting: 'Povoliť hlasovanie',
  category: 'Kategória',
  created_at: 'Dátum vytvorenia',
  modified_at: 'Dátum poslednej zmeny',
  created_by: 'Vytvoril',
  display_type: 'Spôsob zobrazenia výsledkov',
  restriction_type: 'Obmedzenie hlasovania',
  order_date: 'Dátum zobrazenia na webe',
  end_of_voting: 'Ukončenie hlasovania',
  published_until: 'Publikované do',
  postpone: 'Odložiť zmenu',
  published_since: 'Dátum zobrazenia na webe',
  select_date: 'Vyberte dátum',
  options: 'Možnosti',
  option: 'Možnosť',
  option_title: 'Titulok *',
  option_image: 'Obrázok',
  option_position: 'Pozícia',
  option_votes: 'Počet hlasov',
  option_like: 'Páči',
  option_dislike: 'Nepáči',
  add_option: 'Pridať možnosť',
  remove_option: 'Zmazať možnosť',
  total_votes: 'Počet hlasov celkom:',
  migrated_id: 'Migrated ID',
  slug_url: 'Slug URL',
  button_manage_votes: 'Upraviť hlasy',
  results: 'Výsledky ankety',
  management: {
    no_poll_selected: 'Nie je vybraná žiadna anketa',
    create_poll: 'Vytvoriť anketu',
    assign_poll: 'Priradiť anketu',
    remove_poll: 'Odstrániť anketu'
  },
  poll_type: {
    poll: 'Anketa',
    like_dislike: 'Like/Dislike',
    results: 'Výsledok bez hlasovania'
  },
  article_id: 'ID článku',
  generate_poll: 'Generovať anketu z článku',
  fill_article_id: 'Vyplňte ID článku'
}

export default messages
