const messages = {
  copy: {
    first: 'HP blok',
    last: 'bol skopírovaný'
  },
  list: {
    id: 'Id',
    type: 'Typ',
    title: 'Názov',
    identifier: 'Identifikátor',
    number_of_items: 'Počet položiek',
    external_fallback: 'Externý fallback',
    internal_fallback: 'Interný fallback',
    fallback_article_types: 'Fallback typy článkov',
    fallback_article_flags: 'Fallback flagy článkov',
    hidden: 'Skrytý',
    position: 'Pozícia',
    created_at: 'Vytvorený',
    modified_at: 'Upravený',
    created_by: 'Vytvoril'
  },
  filter: {
    site: 'Stránka',
    type: 'Typ',
    title: 'Názov',
    identifier: 'Identifikátor',
    id: 'Id',
    search: 'Hľadať'
  },
  id: 'Id',
  hidden: 'Skrytý',
  type: 'Typ',
  title: 'Názov',
  identifier: 'Identifikátor',
  site: 'Stránka',
  category: 'Kategória',
  number_of_items: 'Počet položiek',
  position: 'Pozícia',
  daysLimit: 'Query limit days',
  display_position: 'Display position',
  disabled_positions: 'Disabled positions',
  fallback_positions: 'Fallback positions',
  external_fallback: 'Enable external fallback',
  internal_fallback: 'Enable internal fallback',
  fallback_site: 'Fallback stránka',
  fallback_rubrics: 'Fallback rubriky',
  fallback_tags: 'Fallback tagy',
  fallback_article_types: 'Article types (logical OR will be applied)',
  fallback_article_flags: 'Article\'s Flags (logical OR will be applied)',
  fallback_article_settings: 'Article\'s Settings',
  fallback_categories: 'Fallback categories',
  content_block_type_value: {
    article: 'Article',
    video_article: 'Video article',
    video: 'Video'
  },
  main_settings: 'Hlavné nastavenia',
  fallback_settings: 'Fallback nastavenia',
  gallery: 'Galéria',
  pr: 'Len PR články'
}

export default messages
