import CoreApi from '../../api/core'
import SiteModel from '../../model/SiteModel.js'
import SiteFilterService from '../../services/site/SiteFilterService'
import Config from '../../config'
import { PERMISSION_CREATE, PERMISSION_EDIT, PERMISSION_VIEW } from '@/model/ValueObject/UserPermissions'

const API_NAME = '/site'
const API_REGION_NAME = '/region'
const LIMIT = 200

const getCurrentUserSiteNames = (rootGetters, permissionModuleName, permission, currentUserPermissionModules) => {
  if (!permissionModuleName) {
    throw Error('"permissionModuleName" cannot be null, see UserPermissionModules.js')
  }
  const currentUser = rootGetters['user/currentUser']
  const modules = currentUserPermissionModules ?? currentUser.permissionModules
  const module = modules?.[permissionModuleName]
  let userSiteNames
  if (module) {
    userSiteNames = module[permission] ?? []
  } else {
    userSiteNames = []
  }
  return userSiteNames
}

const state = {
  error: null,
  detail: SiteModel,
  conflictData: null,
  list: [],
  all: [],
  allAsMap: {},
  regions: [],
  totalCount: 0,
  page: 1,
  filter: {
    id: '',
    name: '',
    title: '',
    site: 0
  },
  width: null
}

const mutations = {
  storeList (state, responseData) {
    state.list = responseData.data
    state.totalCount = responseData.totalCount
  },
  storeAll (state, responseData) {
    state.all = responseData.data
    state.allAsMap = responseData.data.reduce((acc, site) => {
      acc[site.name] = site
      return acc
    }, {})
  },
  storeDetail (state, responseData) {
    state.detail = responseData
  },
  storeRegions (state, responseData) {
    state.regions = responseData
  },
  setPage (state, page) {
    state.page = page
  },
  setError (state, message) {
    state.error = message
  },
  storeConflictData (state, responseData) {
    state.conflictData = responseData
  },
  setFilter (state, filter) {
    state.filter = filter
  },
  setWidth (state, width) {
    state.width = width
  }
}

const actions = {
  fetch (store) {
    store.commit('TOGGLE_LOADING', null, { root: true })
    const offset = (store.state.page * Config.defaults.list.limit) - Config.defaults.list.limit
    const url = API_NAME + '?limit=' + Config.defaults.list.limit + '&offset=' + offset + '&order[id]=asc' +
      SiteFilterService.buildFilterQuery(store.state.filter)
    CoreApi().get(url)
      .then(res => {
        store.commit('storeList', res.data)
        console.log('sites', res.data)
        store.commit('TOGGLE_LOADING', null, { root: true })
      })
      .catch(error => {
        console.log(error)
        store.commit('TOGGLE_LOADING', null, { root: true })
      })
  },
  async fetchRegions (store) {
    await CoreApi().get(API_REGION_NAME)
      .then(res => {
        store.commit('storeRegions', res.data)
      })
      .catch(error => console.error(error))
  },
  async fetchOne (store, id) {
    await CoreApi().get(API_NAME + '/' + id)
      .then(response => {
        store.commit('storeDetail', response.data)
      })
      .catch(error => console.log(error))
  },
  async fetchAll ({ commit }) {
    await CoreApi().get(API_NAME + '?limit=' + LIMIT)
      .then(res => {
        commit('storeAll', res.data)
      })
      .catch(error => console.log(error))
  },
  async update (store, record) {
    store.commit('storeConflictData', null)
    let nullableRecord = record
    if (record.parentSiteId === 0) {
      nullableRecord = { ...nullableRecord, parentSiteId: null }
    }
    return await CoreApi().put(API_NAME + '/' + record.id, JSON.stringify(nullableRecord))
      .then(response => {
        if (response.status === 200) {
          store.commit('setError', null)
          if (Array.isArray(response.data)) {
            store.commit('storeConflictData', response.data)
          } else if (typeof response.data === 'object') {
            store.commit('storeDetail', response.data)
          } else {
            store.commit('setError', 'unsupported data')
          }
        } else {
          store.commit('setError', 'Error')
        }
      })
      .catch(error => {
        if (error.response.status === 500) {
          store.commit('setError', error.response.status)
        } else {
          store.commit('setError', error.response.data.error)
        }
      })
  }
}

const getters = {
  detail (state) {
    return state.detail
  },
  regions (state) {
    return state.regions
  },
  list (state) {
    return state.list
  },
  all (state) {
    return state.all
  },
  allSorted (state) {
    return (compareFn = (a, b) => a.title.localeCompare(b.title)) => state.all.sort(compareFn)
  },
  allEnabledSorted (state) {
    return (compareFn = (a, b) => a.title.localeCompare(b.title)) => state.all.filter(site => site.enabled).sort(compareFn)
  },
  allAsMap (state) {
    return state.allAsMap
  },
  getSiteTitle (state) {
    return siteName => {
      if (!siteName) {
        return ''
      }
      return state.allAsMap[siteName]?.title ??
        siteName.charAt(0).toUpperCase() + siteName.slice(1)
    }
  },
  getTitleById (state) {
    return siteId => {
      const site = state.all.find(site => site.id === siteId)
      if (site) {
        return site.title ?? site.name
      }
      return siteId
    }
  },
  getSiteNameById (state) {
    return siteId => {
      const site = state.all.find(site => site.id === siteId)
      if (site) {
        return site.name
      }
      return siteId
    }
  },
  mapNamesToSites (state) {
    return siteNames => siteNames.map(siteName => state.allAsMap[siteName])
  },
  totalCount (state) {
    return state.totalCount
  },
  page (state) {
    return state.page
  },
  error (state) {
    return state.error
  },
  conflictData (state) {
    return state.conflictData
  },
  filter (state) {
    return state.filter
  },
  siteById (state) {
    return (id, idParam = 'id') => state.all.find(site => id === site[idParam])
  },
  enabledSitesForForm (state, getters, rootState, rootGetters) {
    return (permissionModuleName, formObject, formDisabled) => {
      if (!formObject) {
        throw Error('"formObject" cannot be null, you might want to use "enabledSites" getter')
      }
      const requiredPermission = formDisabled ? PERMISSION_VIEW : (formObject.id ? PERMISSION_EDIT : PERMISSION_CREATE)
      const userSiteNames = getCurrentUserSiteNames(rootGetters, permissionModuleName, requiredPermission, null)
      const formObjectSite = formObject.site ?? formObject.siteId ?? formObject.defaultSite
      return state.all.filter(site => (site.enabled && userSiteNames.includes(site.name)) || site.id === formObjectSite)
    }
  },
  enabledSites (state, getters, rootState, rootGetters) {
    return (permissionModuleName, permission = PERMISSION_VIEW, currentUserPermissionModules = null) => {
      const userSiteNames = getCurrentUserSiteNames(rootGetters, permissionModuleName, permission, currentUserPermissionModules)
      return state.all.filter(site => site.enabled && userSiteNames.includes(site.name))
    }
  },
  userSites (state, getters, rootState, rootGetters) {
    return (permissionModuleName, permission = PERMISSION_VIEW, currentUserPermissionModules = null) => {
      const userSiteNames = getCurrentUserSiteNames(rootGetters, permissionModuleName, permission, currentUserPermissionModules)
      return state.all.filter(site => userSiteNames.includes(site.name))
    }
  }
}

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters
}
