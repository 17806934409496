import CoreApi from '../../api/core'
import PollModel from '../../model/PollModel.js'
import PollFilterService from '../../services/poll/PollFilterService'
import config from '../../config'
import { aiServiceApiV2 } from '@/api/aiService'

const API_NAME = '/poll'

const state = {
  error: null,
  detail: PollModel,
  list: [],
  totalCount: 0,
  page: 1,
  articleId: null,
  filter: {
    id: '',
    title: '',
    type: '',
    category: ''
  }
}

const mutations = {
  storeList (state, responseData) {
    state.list = responseData.data
    state.totalCount = responseData.totalCount
  },
  storeDetail (state, responseData) {
    state.detail = responseData
  },
  resetDetail (state) {
    state.detail = PollModel
  },
  setPage (state, page) {
    state.page = page
  },
  setError (state, message) {
    state.error = message
  },
  setFilter (state, filter) {
    state.filter = filter
  },
  resetFilter (state) {
    state.filter = {
      id: '',
      title: '',
      type: '',
      category: ''
    }
  },
  setArticleId (state, articleId) {
    state.articleId = articleId
  },
  resetArticleId (state) {
    state.articleId = null
  }
}

const actions = {
  fetch (store, type = null) {
    store.commit('TOGGLE_LOADING', null, { root: true })
    const offset = (store.state.page * config.defaults.list.limit) - config.defaults.list.limit
    const url = API_NAME +
      '?limit=' + config.defaults.list.limit +
      '&offset=' + offset +
      '&order[id]=desc' + PollFilterService.buildFilterQuery(store.state.filter)
    CoreApi().get(url)
      .then(res => {
        store.commit('storeList', res.data)
        store.commit('TOGGLE_LOADING', null, { root: true })
      })
      .catch(error => {
        console.log(error)
        store.commit('TOGGLE_LOADING', null, { root: true })
      })
  },
  fetchPolls (store) {
    store.commit('TOGGLE_LOADING', null, { root: true })
    const offset = (store.state.page * config.defaults.list.limit) - config.defaults.list.limit
    const url = API_NAME +
      '?limit=' + config.defaults.list.limit +
      '&offset=' + offset +
      '&filter_in[type]=poll,results' +
      '&order[id]=desc' + PollFilterService.buildFilterQuery(store.state.filter)
    CoreApi().get(url)
      .then(res => {
        store.commit('storeList', res.data)
        store.commit('TOGGLE_LOADING', null, { root: true })
      })
      .catch(error => {
        console.log(error)
        store.commit('TOGGLE_LOADING', null, { root: true })
      })
  },
  fetchLikeDislike (store) {
    store.commit('TOGGLE_LOADING', null, { root: true })
    const offset = (store.state.page * config.defaults.list.limit) - config.defaults.list.limit
    const url = API_NAME +
      '?limit=' + config.defaults.list.limit +
      '&offset=' + offset +
      '&filter_contains[type]=likeDislike' +
      '&order[id]=desc' + PollFilterService.buildFilterQuery(store.state.filter)
    CoreApi().get(url)
      .then(res => {
        store.commit('storeList', res.data)
        store.commit('TOGGLE_LOADING', null, { root: true })
      })
      .catch(error => {
        console.log(error)
        store.commit('TOGGLE_LOADING', null, { root: true })
      })
  },
  async fetchOne (store, id) {
    await CoreApi().get(API_NAME + '/' + id)
      .then(response => {
        store.commit('storeDetail', response.data)
      })
      .catch(error => console.log(error))
  },
  async create (store, record) {
    return await CoreApi().post(API_NAME, JSON.stringify(record))
      .then(response => {
        if (response.status === 201) {
          store.commit('storeDetail', response.data)
          store.commit('setError', null)
        } else {
          store.commit('setError', 'Error')
        }
      })
      .catch(error => {
        if (error.response.status === 500) {
          store.commit('setError', error.response.status)
        } else {
          store.commit('setError', error.response.data.error)
        }
      })
  },
  async update (store, record) {
    return await CoreApi().put(API_NAME + '/' + record.id, JSON.stringify(record))
      .then(response => {
        if (response.status === 200) {
          store.commit('storeDetail', response.data)
          store.commit('setError', null)
        } else {
          store.commit('setError', 'Error')
        }
      })
      .catch(error => {
        if (error.response.status === 500) {
          store.commit('setError', error.response.status)
        } else {
          store.commit('setError', error.response.data.error)
        }
      })
  },
  async updateVotes (store, record) {
    return await CoreApi().put(API_NAME + '/optionsVotes/' + record.id, JSON.stringify(record))
      .then(response => {
        if (response.status === 200) {
          store.commit('storeDetail', response.data)
          store.commit('setError', null)
        } else {
          store.commit('setError', 'Error')
        }
      })
      .catch(error => {
        if (error.response.status === 500) {
          store.commit('setError', error.response.status)
        } else {
          store.commit('setError', error.response.data.error)
        }
      })
  },
  async deleteRecord (store, record) {
    return await CoreApi().delete(API_NAME + '/' + record.id)
      .then(response => {
        if (response.status === 204) {
          store.commit('setError', null)
        } else {
          store.commit('setError', 'Error')
        }
      })
      .catch(error => {
        if (error.response.status === 500) {
          store.commit('setError', error.response.status)
        } else {
          store.commit('setError', error.response.data.error)
        }
      })
  },
  async generatePoll (store, articleBody) {
    try {
      const response = await aiServiceApiV2().post('/generator/poll', { articleBody })
      const detail = {
        ...store.getters.detail,
        title: response.data.question,
        options: response.data.answers.map(item => {
          return { title: item }
        })
      }
      store.commit('storeDetail', detail)
    } catch (error) {
      if (error.response.status === 500) {
        store.commit('setError', error.response.status)
      } else {
        store.commit('setError', error.response.data.error)
      }
    }
  }
}

const getters = {
  detail (state, getters, rootState, rootGetters) {
    return state.detail
  },
  list (state) {
    return state.list
  },
  totalCount (state) {
    return state.totalCount
  },
  page (state) {
    return state.page
  },
  error (state) {
    return state.error
  },
  filter (state) {
    return state.filter
  },
  articleId (state) {
    return state.articleId
  }
}

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters
}
